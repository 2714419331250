import React from "react";
import { Container } from "../../elements/Container";
import { IItemProps, Item } from "./components/Item/Item";
import { styles } from "./UniqueSellingPointsSmall.style";

export interface IUniqueSellingPointsSmallProps {
  title: string;
  items: IItemProps[];
}

export function UniqueSellingPointsSmall({ title, items }: IUniqueSellingPointsSmallProps) {
  return (
    <Container>
      <div css={styles.root}>
        <h2>{title}</h2>
        <div css={styles.itemsContainer}>
          {items.map((item, index) => (
            <Item key={index} image={item.image} label={item.label}></Item>
          ))}
        </div>
      </div>
    </Container>
  );
}
