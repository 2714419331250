import React from "react";
import { Container } from "../../elements/Container";
import { IItemProps, Item } from "./components/Item/Item";
import { styles } from "./UniqueSellingPointsBig.style";

export interface UniqueSellingPointsProps {
  title: string;
  items: IItemProps[];
}

export const UniqueSellingPointsBig: React.FC<UniqueSellingPointsProps> = ({ title, items }) => {
  return (
    <Container>
      <div css={styles.root}>
        <h2>{title}</h2>
        <div css={styles.itemsContainer}>
          {items.map((item) => (
            <Item key={item.label} image={item.image} label={item.label} text={item.text}></Item>
          ))}
        </div>
      </div>
    </Container>
  );
};
