import React from "react";
import { Icon } from "../../../../elements/Icon";
import { styles } from "./Item.style";

export interface IItemProps {
  image: string;
  label: string;
}

export function Item({ image, label }) {
  return (
    <div css={styles.root}>
      <Icon icon={image} css={styles.icon}></Icon>
      <div css={styles.label}>{label}</div>
    </div>
  );
}
