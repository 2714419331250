import { css } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "../../../../core/styles";

export const styles = {
  root: () =>
    css({
      width: "162px",
      height: "162px",
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.cat,
      letterSpacing: letterSpacings.body.horse,
      backgroundColor: colors.white.hex,
      display: "flex",
      flexDirection: "column",
      color: colors.headlineGrey.hex,
      border: `1px solid ${colors.disabledGrey.hex}`,
      borderRadius: "4px",
      textAlign: "center",
      justifyContent: "flex-start",
      paddingTop: spacing.baseUnitMobile4,
      [mediaQuery.desktopOrLarger]: {
        width: "160px",
        minHeight: "160px",
      },
    }),
  icon: () =>
    css({
      margin: "0 auto",
      svg: {
        width: "32px",
        height: "32px",
      },
    }),
  label: () =>
    css({
      padding: spacing.baseUnitMobile1,
    }),
};
