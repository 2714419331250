import React from "react";
import { UniqueSellingPointsBig } from "@carfax/web-ui/components/UniqueSellingPointsBig";
import { UniqueSellingPointsSmall } from "@carfax/web-ui/components/UniqueSellingPointsSmall";
import { ModuleUsps } from "../../../contentful/PageModularModule";

interface Props {
  module: ModuleUsps;
}

const Usps: React.FC<Props> = ({ module: { version, ...props } }) => {
  return <>{version ? <UniqueSellingPointsSmall {...props} /> : <UniqueSellingPointsBig {...props} />}</>;
};

export default Usps;
