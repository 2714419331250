import { css } from "@emotion/react";
import { colors, mediaQuery, spacing } from "../../core/styles";

export const styles = {
  root: () =>
    css({
      maxWidth: "336px",
      margin: "0 auto",
      color: colors.headlineGrey.hex,
      h2: {
        textAlign: "center",
        marginBottom: spacing.baseUnitMobile2,
      },
      [mediaQuery.desktopOrLarger]: {
        maxWidth: "718px",
        h2: {
          marginBottom: spacing.baseUnitMobile3,
        },
      },
    }),
  itemsContainer: () =>
    css({
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "6px",
      [mediaQuery.desktopOrLarger]: {
        gap: spacing.baseUnitMobile3,
      },
    }),
};
