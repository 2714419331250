import { css } from "@emotion/react";
import { colors, mediaQuery, spacing } from "../../core/styles";

export const styles = {
  root: () =>
    css({
      maxWidth: "896px",
      margin: "0 auto",
      color: colors.headlineGrey.hex,
      h2: {
        textAlign: "center",
        marginBottom: spacing.baseUnitMobile2,
      },
      [mediaQuery.desktopOrLarger]: {
        h2: {
          marginBottom: spacing.baseUnitMobile3,
        },
      },
    }),
  itemsContainer: () =>
    css({
      display: "flex",
      [mediaQuery.smallerThanTablet]: {
        flexWrap: "wrap",
        justifyContent: "center",
      },
      [mediaQuery.desktopOrLarger]: {
        justifyContent: "space-between",
      },
    }),
};
